import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { FooterBar } from './components/footerBar/FooterBar';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IContasBancarias,
  IChavePix,
  newPixKeyService,
  IChavePixConfig,
} from 'shared/services/api/my-credentials/PixKeyConfig';
import { feedback } from 'shared/services';
import { IEstabelcimento } from 'shared/services/api/my-credentials/MyCredentials';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useTitle } from 'shared/hooks';
import { confirm } from 'shared/services';
import { UploadFiles } from './components/uploadFiles/uploadFiles';

const useStyles = makeStyles(() => {
  return {
    paperBackGround: {
      backgroundColor: `#ccf1ef !important`,
      paddingBottom: '3rem !important',
    },
    backGround: {
      background: `#ffff !important`,
    },

    backGroundCertificate: {
      background: `#ffff !important`,
      border: '2px',
      borderRadius: '30px',
    },
  };
});

export const PixKeyConfig: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const pixNavigateState = {
    state: '0',
  };
  const handleBack = () => {
    navigate(`/minhas-credenciais`, pixNavigateState);
  };
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle('Configuração chave PIX');
  }, [setTitle]);

  const { id = '' } = useParams<'id'>();
  const [isLoading, setIsLoading] = useState(true);
  const [Certificatefile, setCertificateFile] = useState('');
  const [estabelecimentos, setEstabelecimento] = useState<IEstabelcimento[]>(
    [],
  );
  const [contasBancarias, setContasBancarias] = useState<IContasBancarias[]>(
    [],
  );
  const [credentials, setCredentials] = useState<IChavePixConfig>();
  const [newPixKey, setNewPixKey] = useState<IChavePix>({
    id: '',
    nomeBanco: '',
    email: '',
    contaBancaria: '',
    chavePixDoRecebedor: '',
    chavePixTipoId: 0,
    codigoBanco: '',
    nomeEstabelecimento: '',
    contaBancariaId: 0,
    contaBancariaDescricao: '',
    cnpjEstabelecimento: '',
    chavePixConfigs: {
      basic: {
        valor: '',
        habilitado: false,
      },
      clientId: {
        valor: '',
        habilitado: false,
      },
      clientSecret: {
        valor: '',
        habilitado: false,
      },
      developerApplicationKey: {
        valor: '',
        habilitado: false,
      },

      clientCertificate: {
        valor: '',
        habilitado: false,
      },
      passPhrase: {
        valor: '',
        habilitado: false,
      },
      merchantCity: {
        valor: '',
        habilitado: false,
      },
      merchantName: {
        valor: '',
        habilitado: false,
      },
    },
  });

  useEffect(() => {
    if (Certificatefile) {
      setNewPixKey({
        ...newPixKey,
        chavePixConfigs: {
          ...newPixKey.chavePixConfigs,
          clientCertificate: {
            ...newPixKey.chavePixConfigs.clientCertificate,
            valor: Certificatefile,
            habilitado: true,
          },
        },
      });
    }

    // eslint-disable-next-line
  }, [Certificatefile]);

  useEffect(() => {
    async function getContasBancarias() {
      try {
        const response = await newPixKeyService.getContasBancarias();

        setContasBancarias(response);
        setIsLoading(false);
      } catch (error: any) {
        feedback(error.message, 'error');
      }
    }
    getContasBancarias();
  }, []);

  const getCredentials = useCallback(async () => {
    try {
      const response = await newPixKeyService.getCredenciais(
        newPixKey.codigoBanco,
      );
      setCredentials(response);
    } catch (error: any) {
      ('');
    }
  }, [newPixKey.codigoBanco]);

  const submitPixConfig = useCallback(async () => {
    feedback('Salvando...', 'loading');
    if (id) {
      try {
        const response = await newPixKeyService.putPixKey(newPixKey, id || '');
        if (response.sucesso) {
          feedback('Salvo com sucesso', 'success');
          navigate(`/minhas-credenciais`, pixNavigateState);
        } else {
          feedback(
            response.mensagensCombinadas || 'Erro ao salvar configuração',
            'error',
          );
        }
      } catch (error: any) {
        ('');
      }
    } else {
      const response = await newPixKeyService.postChavePix(newPixKey);
      if (response.sucesso) {
        feedback('Conta pix criada com sucesso!', 'success');
        navigate(`/minhas-credenciais`, pixNavigateState);
      } else {
        feedback(
          response.mensagensCombinadas || 'Erro ao salvar configuração',
          'error',
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPixKey, navigate, id]);

  const getParametrosEdit = useCallback(async () => {
    try {
      const response = await newPixKeyService.getPixParametros(id);
      setNewPixKey(response);
      setIsLoading(false);
    } catch (error: any) {
      feedback(error.Mensagem, 'error');
    }
  }, [id]);

  const handleOperation = useCallback(async () => {
    try {
      if (id) {
        getParametrosEdit();
      } else {
        getCredentials();
      }
    } catch (error: any) {
      feedback(error.Mensagem, 'error');
    } finally {
      ('');
    }
  }, [id, getParametrosEdit, getCredentials]);

  useEffect(() => {
    handleOperation();
  }, [handleOperation]);

  const DeletePixKey = useCallback(async () => {
    feedback('Deletando...', 'loading');
    try {
      newPixKeyService.deletePixKey(id || '');
    } catch (error: any) {
      feedback(error.Mensagem, 'error');
    } finally {
      feedback('Deletado com sucesso', 'success');
      navigate(`/minhas-credenciais`, pixNavigateState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate]);

  const handleDelteConfig = useCallback(async () => {
    confirm(
      'Tem certeza que deseja excluir este registro?',
      'delete',
      DeletePixKey,
    );
  }, [DeletePixKey]);

  return (
    <Grid container>
      <Grid item>
        {id ? (
          <Typography variant="h5" color="primary">
            Editar Chave PIX
          </Typography>
        ) : (
          <Typography variant="h5" color="primary">
            Nova Chave PIX
          </Typography>
        )}
      </Grid>

      <Grid container item>
        <Grid item xs={3}>
          <Box marginTop={5}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Tipo de chave</InputLabel>
              <Select
                value={
                  newPixKey.chavePixTipoId === 0 ? '' : newPixKey.chavePixTipoId
                }
                label="Tipo de chave"
                onChange={(e) =>
                  setNewPixKey(
                    (state) =>
                      (state = {
                        ...state,
                        chavePixTipoId: Number(e.target.value),
                      }),
                  )
                }
              >
                <MenuItem value={1}>CPF/CNPJ</MenuItem>
                <MenuItem value={2}>Número de telefone celular</MenuItem>
                <MenuItem value={3}>E-mail</MenuItem>
                <MenuItem value={4}>Chave aleatória</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs>
          <Box marginTop={5} marginLeft={2}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.backGround}
            >
              <InputLabel>Chave PIX</InputLabel>
              <OutlinedInput
                label="Chave PIX"
                type="string"
                value={newPixKey.chavePixDoRecebedor}
                onChange={(e) =>
                  setNewPixKey(
                    (state) =>
                      (state = {
                        ...state,
                        chavePixDoRecebedor: e.target.value,
                      }),
                  )
                }
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs>
          <Box marginTop={5} marginLeft={2}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.backGround}
            >
              <InputLabel>Email</InputLabel>
              <OutlinedInput
                label="Email"
                value={newPixKey.email}
                onChange={(e) => {
                  setNewPixKey({
                    ...newPixKey,
                    email: e.target.value,
                  });
                }}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={3}>
          <Box marginTop={2.5}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Conta Bancária</InputLabel>
              <Select
                disabled={isLoading}
                value={
                  newPixKey.contaBancariaId === 0
                    ? ''
                    : newPixKey.contaBancariaId
                }
                label="Conta Bancária"
                onChange={(e) =>
                  setNewPixKey(
                    (state) =>
                      (state = {
                        ...state,
                        contaBancariaId: Number(e.target.value),
                      }),
                  )
                }
              >
                {contasBancarias?.map((item) => (
                  <MenuItem
                    key={item.IdContaBancaria}
                    value={item.IdContaBancaria}
                    onClick={() => {
                      if (!id) {
                        getCredentials;
                      }
                      setEstabelecimento(item.Estabelcimentos);
                      setNewPixKey(
                        (state) =>
                          (state = {
                            ...state,
                            contaBancaria: item.NumeroConta,
                            codigoBanco: item.CodigoBanco,
                            contaBancariaDescricao: item.DescricaoConta,
                            nomeBanco: item.NomeBanco,
                          }),
                      );
                    }}
                  >
                    {item.DescricaoConta}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {isLoading ? <LinearProgress /> : <></>}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box marginTop={2.5} marginLeft={2}>
            <FormControl
              disabled
              variant="outlined"
              fullWidth
              className={classes.backGround}
            >
              <InputLabel>Instituição Bancária</InputLabel>
              <OutlinedInput
                defaultValue={newPixKey.nomeBanco}
                value={newPixKey.nomeBanco || ''}
                label="Instituição Bancária"
                type="string"
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs>
          <Box marginTop={2.5} marginLeft={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Estabelecimento</InputLabel>
              <Select label="Estabelecimento">
                {estabelecimentos?.map((item) => (
                  <MenuItem
                    key={item.Id}
                    value={item.Id}
                    onClick={() => {
                      setNewPixKey(
                        (state) =>
                          (state = {
                            ...state,
                            cnpjEstabelecimento: item.CNPJ,
                            nomeEstabelecimento: item.Nome,
                          }),
                      );
                    }}
                  >
                    {item.Nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      {credentials || id ? (
        <Box marginTop={5} width={'100%'} justifySelf={'center'}>
          <Paper elevation={0} className={classes.paperBackGround}>
            <Box paddingX={4} paddingY={2}>
              <Typography variant="h6" color={'primary'}>
                Credenciais
              </Typography>
            </Box>

            {newPixKey.chavePixConfigs.developerApplicationKey.habilitado ||
            credentials?.developerApplicationKey.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>DeveloperApplicationKey</InputLabel>
                      <OutlinedInput
                        label="DeveloperApplicationKey"
                        type="string"
                        value={
                          newPixKey.chavePixConfigs.developerApplicationKey
                            .valor
                        }
                        onChange={(e) =>
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              developerApplicationKey: {
                                ...newPixKey.chavePixConfigs
                                  .developerApplicationKey,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>
                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              developerApplicationKey: {
                                ...newPixKey.chavePixConfigs
                                  .developerApplicationKey,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newPixKey.chavePixConfigs.clientId.habilitado ||
            credentials?.clientId.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>ClientId</InputLabel>
                      <OutlinedInput
                        label="ClientId"
                        type="string"
                        value={newPixKey.chavePixConfigs.clientId.valor}
                        onChange={(e) =>
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              clientId: {
                                ...newPixKey.chavePixConfigs.clientId,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              clientId: {
                                ...newPixKey.chavePixConfigs.clientId,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newPixKey.chavePixConfigs.clientSecret.habilitado ||
            credentials?.clientSecret.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>ClientSecret</InputLabel>
                      <OutlinedInput
                        label="ClientSecret"
                        type="string"
                        value={newPixKey.chavePixConfigs.clientSecret.valor}
                        onChange={(e) =>
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              clientSecret: {
                                ...newPixKey.chavePixConfigs.clientSecret,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>
                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              clientSecret: {
                                ...newPixKey.chavePixConfigs.clientSecret,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newPixKey.chavePixConfigs.basic.habilitado ||
            credentials?.basic.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>Basic</InputLabel>
                      <OutlinedInput
                        label="Basic"
                        type="string"
                        value={newPixKey.chavePixConfigs.basic.valor}
                        onChange={(e) =>
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              basic: {
                                ...newPixKey.chavePixConfigs.basic,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              basic: {
                                ...newPixKey.chavePixConfigs.basic,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newPixKey.chavePixConfigs.merchantCity.habilitado ||
            credentials?.merchantCity.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>merchantCity</InputLabel>
                      <OutlinedInput
                        label="merchantCity"
                        type="string"
                        value={newPixKey.chavePixConfigs.merchantCity.valor}
                        onChange={(e) =>
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              merchantCity: {
                                ...newPixKey.chavePixConfigs.merchantCity,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              merchantCity: {
                                ...newPixKey.chavePixConfigs.merchantCity,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newPixKey.chavePixConfigs.merchantName.habilitado ||
            credentials?.merchantName.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>merchantName</InputLabel>
                      <OutlinedInput
                        label="merchantName"
                        type="string"
                        value={newPixKey.chavePixConfigs.merchantName.valor}
                        onChange={(e) =>
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              merchantName: {
                                ...newPixKey.chavePixConfigs.merchantName,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              merchantName: {
                                ...newPixKey.chavePixConfigs.merchantName,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {(newPixKey.chavePixConfigs.clientCertificate.habilitado &&
              newPixKey.chavePixConfigs.passPhrase.habilitado) ||
            (credentials?.clientCertificate.habilitado &&
              credentials.clientCertificate.habilitado) ? (
              <Box paddingX={4} paddingY={2}>
                <Typography variant="h6" color={'primary'}>
                  Certificado
                </Typography>
              </Box>
            ) : (
              <></>
            )}

            {newPixKey.chavePixConfigs.clientCertificate.habilitado ||
            credentials?.clientCertificate.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    width={'97.1%'}
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGroundCertificate}
                    >
                      <InputLabel shrink> ClientCertificate</InputLabel>

                      <UploadFiles
                        setFile={setCertificateFile}
                        id={id ? true : false}
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {newPixKey.chavePixConfigs.passPhrase.habilitado ||
            credentials?.passPhrase.habilitado ? (
              <Grid container item xs>
                <Grid item xs>
                  <Box
                    paddingX={7}
                    marginTop={2}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.backGround}
                    >
                      <InputLabel>passPhrase</InputLabel>
                      <OutlinedInput
                        label="passPhrase"
                        type="string"
                        value={newPixKey.chavePixConfigs.passPhrase.valor}
                        onChange={(e) =>
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              passPhrase: {
                                ...newPixKey.chavePixConfigs.passPhrase,
                                valor: e.target.value,
                                habilitado: true,
                              },
                            },
                          })
                        }
                      />
                    </FormControl>

                    <IconButton
                      onClick={() =>
                        navigator.clipboard.readText().then((text) => {
                          setNewPixKey({
                            ...newPixKey,
                            chavePixConfigs: {
                              ...newPixKey.chavePixConfigs,
                              passPhrase: {
                                ...newPixKey.chavePixConfigs.passPhrase,
                                valor: text,
                                habilitado: true,
                              },
                            },
                          });
                        })
                      }
                    >
                      <ContentPasteIcon color="primary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Paper>
        </Box>
      ) : (
        <Box marginTop={3}>
          <Typography variant="h6" color={'primary'}>
            <i>Selecione uma conta bancária para visualizar as credenciais.</i>
          </Typography>
        </Box>
      )}

      {id ? (
        <FooterBar
          onCancel={handleBack}
          onSave={submitPixConfig}
          onExcluirChave={handleDelteConfig}
        />
      ) : (
        <FooterBar onCancel={handleBack} onSave={submitPixConfig} />
      )}
    </Grid>
  );
};
