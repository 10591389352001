/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosInstance } from 'axios';

import { Environment } from 'shared/env';
import { errorInterceptor, responseInterceptor } from './interceptors';

export const Api = (): AxiosInstance => {
  const headers = {} as any;
  if (location.host.includes('localhost')) {
    headers.origin_debug = `${Environment.URL_ORIGIN_DEBUG}`;
  }

  const config = Axios.create({
    baseURL: Environment.URL_API,
    withCredentials: true,
    headers,
  });

  config.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
  );

  return config;
};

export const ApiGateway = (): AxiosInstance => {
  const headers = {} as any;
  if (location.host.includes('localhost')) {
    headers.urlOrigin = 'https://api-dev.pay.gvdasa.com.br';
  }

  const config = Axios.create({
    baseURL: Environment.URL_GATEWAY,
    withCredentials: true,
    headers,
  });

  config.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
  );

  return config;
};

export const ApiBoleto = (): AxiosInstance => {
  const headers = {} as any;
  if (location.host.includes('localhost')) {
    headers.origin_debug = `${Environment.URL_ORIGIN_DEBUG}`;
  }

  const config = Axios.create({
    baseURL: `${Environment.URL_PAY_BOLETO}`,
    withCredentials: true,
    headers,
  });

  config.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
  );

  return config;
};

export const ApiPix = (): AxiosInstance => {
  const headers = {} as any;
  if (location.host.includes('localhost')) {
    headers.origin_debug = `${Environment.URL_ORIGIN_DEBUG}`;
  }

  const config = Axios.create({
    baseURL: `${Environment.URL_PAY_PIX}`,
    withCredentials: true,
    headers,
  });

  config.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
  );

  return config;
};

export const ApiGatewayBoelto = (): AxiosInstance => {
  const headers = {} as any;
  if (location.host.includes('localhost')) {
    headers.urlOrigin = `${Environment.URL_ORIGIN_DEBUG}`;
  }

  const config = Axios.create({
    baseURL: 'https://api-dev.gvdasa.com.br/paybh/api/v1',
    withCredentials: true,
    headers,
  });

  config.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error),
  );

  return config;
};
