import React, { useCallback, useEffect, useState } from 'react';

import { Box, TextField, Tooltip, Typography, Skeleton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Agendador } from 'shared/components';
import {
  IConfiguracaoRecorrencia,
  RecorrenciaService,
} from 'shared/services/api';
import { feedback } from 'shared/services';
import { useParams } from 'react-router-dom';

interface IParamsTypes {
  idRecorrencia: string;
}

export const RecorrenciaDetalhe: React.FC = () => {
  const [edit, setEdit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IConfiguracaoRecorrencia>({
    QtdDiasTentativa: 1,
  } as IConfiguracaoRecorrencia);

  const { idRecorrencia } = useParams<keyof IParamsTypes>();

  const handleGetData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await RecorrenciaService.getConfiguracaoRecorrencia();

      if (res) {
        setData(res);
        setEdit(true);
      } else {
        setEdit(false);
      }
    } catch (error) {
      feedback('Erro ao buscar as informações', 'error');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  const handlePostOrPutData = useCallback(
    async (id: string) => {
      if (data.QtdDiasTentativa < 0 || id.length === 0) {
        setSubmitted(true);
        return;
      }

      try {
        setIsLoading(true);
        if (edit) {
          await RecorrenciaService.editConfiguracaoRecorrencia({
            Id: data.ConfigRecorrenciaId,
            QtdDiasTentativa: data.QtdDiasTentativa,
          });
        } else {
          await RecorrenciaService.createConfiguracaoRecorrencia({
            QtdDiasTentativa: data.QtdDiasTentativa,
          });
        }
      } catch (error) {
        //
      } finally {
        setIsLoading(false);
      }
    },
    [data, edit],
  );

  return (
    <>
      {isLoading ? (
        <>
          <Box padding={1.7} display="flex" flexDirection="column" gap={1}>
            <Skeleton variant="text" width={210} />
            <Skeleton variant="rectangular" width={200} height={40} />
          </Box>

          <Box padding={1.7} display="flex" flexDirection="column" gap={1}>
            <Skeleton variant="text" width={150} />
            <Skeleton variant="rectangular" width={150} height={40} />
          </Box>

          <Box padding={1.7} display="flex" flexDirection="column" gap={1}>
            <Skeleton variant="text" width={50} />
          </Box>

          <Box padding={1.7} display="flex" gap={1}>
            <Box display="flex" flexDirection="column" gap={1}>
              <Skeleton variant="text" width={50} />
              <Skeleton variant="rectangular" width={180} height={40} />
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              <Skeleton variant="text" width={50} />
              <Box display="flex" gap={1} padding={1.2}>
                <Skeleton variant="rectangular" width={20} height={20} />
                <Skeleton variant="text" width={50} />
              </Box>
            </Box>
          </Box>

          <Box padding={1.7} display="flex" flexDirection="column" gap={1}>
            <Skeleton variant="text" width={100} />
          </Box>

          <Box padding={1.7} display="flex" flexDirection="column" gap={1}>
            <Skeleton variant="text" width={150} />
            <Skeleton variant="rectangular" width={200} height={40} />
          </Box>

          <Box padding={1.7} display="flex" justifyContent="flex-end">
            <Skeleton variant="rectangular" width={80} height={40} />
          </Box>
        </>
      ) : (
        <>
          <Box padding={1.7}>
            <Typography variant={'h5'}>Configuração GV Pay</Typography>
            <Box display="flex" gap={1} marginTop={3}>
              <Typography>Intervalo de dias para verificação</Typography>
              <Tooltip
                title="Defina a quantidade de dias após data do início do agendamento que ele irá fazer as verificações das transações."
                placement="right"
                color="primary"
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Box>
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              type="number"
              error={data.QtdDiasTentativa < 0 && submitted}
              helperText={
                data.QtdDiasTentativa < 0 &&
                submitted &&
                'Deve conter um valor maior ou igual a zero'
              }
              value={data.QtdDiasTentativa}
              onChange={({ target }) =>
                setData(
                  (state) =>
                    (state = {
                      ...state,
                      QtdDiasTentativa: Number(target.value),
                    }),
                )
              }
            />
          </Box>

          <Agendador
            idTarefa="GvpayRecorrencia"
            idClient="gvpay"
            tipoOcorrencia="dinamica"
            topico="agendamento_recorrencia"
            onAgendaSalva={() => handlePostOrPutData(idRecorrencia || '')}
            nomeTarefa="Recorrência"
            descricao=""
            fuso="GMT-3"
            idAgendamento={idRecorrencia || ''}
          />
        </>
      )}
    </>
  );
};
