import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import {
  AlertComponent,
  ConfirmationDialogComponent,
  SplashScreen,
} from 'shared/components';
import {
  ThemesProvider,
  TitleProvider,
  AuthProvider,
  UserProvider,
} from 'shared/contexts';
import { Layout } from 'shared/layouts';
import { AppRoutes } from 'routes';
import { Environment } from 'shared/env';

export const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const scriptAgendameto = document.createElement('script');
    scriptAgendameto.type = 'module';
    scriptAgendameto.src = `${Environment.URL_WC_AGENDAMENTOS}`;
    scriptAgendameto.crossOrigin = 'anonymous';

    const scriptCartao = document.createElement('script');
    scriptCartao.type = 'module';
     scriptCartao.src = `${Environment.URL_WC_CARTAO}`;
    scriptCartao.crossOrigin = 'anonymous';

    const scriptPix = document.createElement('script');
    scriptPix.type = 'module';
    scriptPix.src = `${Environment.URL_WC_PIX}`;
    scriptPix.crossOrigin = 'anonymous';

    const scriptFeedBack = document.createElement('script');
    scriptFeedBack.type = 'module';
    scriptFeedBack.src = `${Environment.URL_WC_FEEDABACK}`;
    scriptFeedBack.crossOrigin = 'anonymous';

    document.body.appendChild(scriptAgendameto);
    document.body.appendChild(scriptCartao);
    document.body.appendChild(scriptPix);
    document.body.appendChild(scriptFeedBack);

    return () => {
      document.body.removeChild(scriptAgendameto);
      document.body.removeChild(scriptCartao);
      document.body.removeChild(scriptPix);
      document.body.appendChild(scriptFeedBack);
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <ThemesProvider>
        <UserProvider>
          {isLoading ? (
            <SplashScreen setIsLoading={setIsLoading} />
          ) : (
            <AuthProvider>
              <TitleProvider>
                <BrowserRouter>
                  <Layout>
                    <AppRoutes />
                    <AlertComponent />
                    <ConfirmationDialogComponent />
                  </Layout>
                </BrowserRouter>
              </TitleProvider>
            </AuthProvider>
          )}
        </UserProvider>
      </ThemesProvider>
    </>
  );
};
